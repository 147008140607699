<template>
  <div>
    <div class="mb-10 mt-10 grid grid-cols-2">
      <span class="text-xl mb-6 uppercase tracking-wide"> Periode </span>
      <div class="text-right pr-3">
        <!-- trigger add new period -->
        <button class="button --primary ml-4" @click="showForm">
          <fa-icon icon="plus"></fa-icon>
          <span class="ml-2">Periode</span>
        </button>
      </div>
    </div>

    <!-- period list -->
    <div class="p-2 text-center uppercase tracking-wider" v-if="!hasPeriod">
      Belum ada periode pendaftaran aktif
    </div>
    <div class="sm:grid sm:gap-4 sm:grid-cols-2 lg:grid-cols-3">
      <period-list-item
        v-for="(item, key) in periods"
        :key="key"
        :period="item"
        class="mb-4 bg-white rounded"
      ></period-list-item>
    </div>

    <!-- pagination -->
    <pagination-links></pagination-links>

    <!-- editing form -->
    <period-form></period-form>
  </div>
</template>



<script>
import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
  methods: {
    showForm() {
      this.SHOW_FORM();
    },
    ...mapMutations("period", ["SHOW_FORM"]),
    ...mapActions("period", ["fetchPeriods"]),
  },
  computed: {
    hasPeriod() {
      return this.periods.length > 0;
    },
    ...mapGetters("period", ["periods"]),
  },
  async mounted() {
    await this.fetchPeriods();
  },
  name: "PeriodList",
  components: {
    PeriodForm: () => import("@/components/Registration/Period/PeriodForm.vue"),
    PaginationLinks: () => import("@/components/PaginationLinks.vue"),
    PeriodListItem: () =>
      import("@/components/Registration/Period/PeriodListItem.vue"),
  },
};
</script>